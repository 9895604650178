import React from "react"

import Layout from "../../components/layout"
import SEO from "../../components/seo"
import imgCover from "../../assets/images/mission/scientific-objectives/cover.png"
import imgHope from "../../assets/images/mission/scientific-objectives/hope-probe.png"
import imgRocket from "../../assets/images/hope-probe/launch-vehicle/rocket.png"
import { Link } from "gatsby"

const LaunchVehiclePage = () => (
    <Layout>
        <SEO title="Science" />
        <main>
            <div className="uk-cover-container" data-uk-height-viewport="offset-top: true; offset-bottom: 40">
                <img src={imgCover} alt="" data-uk-cover />
                <div className="uk-overlay uk-position-cover header-overlay"></div>
            </div>
            <section id="about-emm" style={{ marginTop: "-100px", position: "relative" }}>
                <div className="large-container pb6">
                    <div className="standard-container standard-bg" style={{ paddingBottom: "0px" }}>
                        <div className="mb5 relative">
                            <h2 className="relative">
                                SCIENCE
                    <div className="text-underline-heavy"></div>
                            </h2>

                            <h3>Historical Evolution</h3>
                            <p>
                                Images of the surface of Mars indicates evidence of a warmer, and wetter planet than what
                                we see today. One of the culprits of the transformation of this planet into a dry, dusty one is
                                climate change and atmospheric loss. Scientists around the world continue to study the
                                historic evolution of Mars’ atmosphere, and the Emirates Mars Mission aims to provide data
                                that will bridge our gap in understanding the present-day climate of Mars.
                            </p>

                            <h3>Mars’ First Weather Satellite</h3>
                            <p>
                                The Hope Probe will study the weather system of Mars, monitoring for the first-time
                                weather changes throughout the day, across the planet, during all seasons. Simultaneously,
                                Hope Probe will monitor the distribution of hydrogen and oxygen in the upper portions of Mars’
                                atmosphere (the exosphere). Hope Probe will also focus on better understanding the link between
                                weather changes in Mars’ lower atmosphere, with loss of hydrogen and oxygen from the
                                upper layers of the atmosphere. This for the first time will allow us to study the link
                                between weather change and atmospheric loss, a process that may have been responsible
                                for Mars’ transition, over billions of years, from a thick atmosphere capable of sustaining
                                liquid water on the surface, to the cold, thin, arid atmosphere we see today.

                            </p>

                            <h3>The Hope Probe’s Instrument Suite</h3>
                            <p>
                                The Hope Probe will be able to study the atmosphere from a science orbit of 20,000 km
                                periapsis and 43,000 km apoapsis, with an orbital period of 55 hours and orbital inclination
                                of 25°. No other Mars spacecraft has had such an orbit; most orbit at a single local time that
                                allows the atmosphere to be measured at only one time of day. Hope Probe carries a suite of three
                                instruments which work simultaneously to observe key constituents within the atmosphere.

                            </p>

                            <h3>Emirates eXploration Imager (EXI)</h3>
                            <p>
                                EXI is a multi-wavelength imager capable of capturing 12 mega-pixel visible images of Mars.
                                EXI also measures the distribution of water ice and ozone in the lower atmosphere utilizing
                                the ultraviolet bands.
                            </p>

                            <h3>Emirates Mars Infrared Spectrometer (EMIRS)</h3>
                            <p>
                                EMIRS observes Mars in the infrared band measures the optical depth of dust, ice clouds
                                and water vapor in the atmosphere. EMIRS also measures the temperature of the surface
                                and the lower atmosphere of Mars.

                            </p>

                            <h3>Emirates Mars Ultraviolet Spectrometer (EMUS)</h3>

                            <p>
                                EMUS studies the upper atmosphere of Mars through the far ultraviolet wavelengths. It
                                determines the distribution of carbon monoxide and oxygen in the thermosphere. EMUS
                                also measures the distribution of oxygen and hydrogen in the exosphere of Mars.
                            </p>
                            {/* <p className="mt5">
                  Our understanding of Mars’ atmosphere has been significantly limited by the fixed local time of recent measurements made by several spacecraft, leaving most of the Mars diurnal (i.e. day-to-night) cycle unexplored over much of the planet. Thus, important information about how atmospheric processes drive diurnal variations is missing. This limited coverage has hindered our understanding of the transfer of energy from the lower-middle atmosphere to the upper atmosphere. EMM will be able to observe and investigate the Mars lower and upper atmosphere simultaneously, enabled by a high-altitude orbit and synoptic perspective. Views of most of the planet’s surface at most times of day will enable unprecedented studies of the physical processes that drive the global atmospheric circulation, temperature structure, and the distribution and interaction of ice clouds, water vapor and dust (which can shroud the surface in spectacular storms).                  </p>
                  <div className="flex flex-column flex-row-ns">
                      <div className="w-100 w-50-ns">
                        <p>
                            In addition, EMM will reveal the connection between these conditions in the lower atmosphere and the escape of hydrogen and oxygen from the upper atmosphere, a process that may have been responsible for Mars’ transition, over billions of years, from a thick atmosphere capable of sustaining liquid water on the surface, to the cold, thin, arid atmosphere we see today. Table 1 highlights the uniqueness of EMM, comparing it to past missions in terms of coverage and the relevant science topics.
                        </p>
                        <h3 className="dn db-ns">
                        EMM Science Questions and Objectives
                        </h3>
                      </div>
                      <div className="w-100 w-50-ns">
                          <img src={imgHope} className="negative-margin-right-150" alt=""/>
                      </div>
                  </div> */}

                        </div>
                        {/* <div className="mv5-ns mv3">
                    <h3 className="db dn-ns">
                    EMM Science Questions and Objectives
                    </h3>
                </div> */}
                        <div>Learn more about the <Link to="/hope-probe/instruments">instruments on the Hope Probe </Link></div>
                    </div>
                    {/* <div className="uk-grid uk-grid-small uk-child-width-expand@s uk-text-center" data-uk-grid>
                <div>
                    <div className="animated-card animated-card-text-trigger">
                        <div className="flex flex-column justify-center uk-card-secondary"  style={{minHeight: "250px"}}>
                            <div className="pa2">
                                <p className="uk-text-bold text-light mb0 pb0">
                                How does the Martian lower atmosphere respond globally, diurnally and seasonally to solar forcing?
                                </p>
                            </div>
                            <div className="ph2 animated-card-text relative">
                                <div className="absolute left-1" style={{fontSize: "6rem", lineHeight: "6rem", color: "#0a0a0a", fontWeight: "800", opacity: "0.5"}}>
                                    A
                                </div>
                                <p className="text-light uk-text-left uk-text-small pl5">
                                    Charaterize the state of Martian lower atmosphere on global scales and it's geographic, diurnal and seasonal variability.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
                <div>
                    <div className="animated-card animated-card-text-trigger">
                        <div className="flex flex-column justify-center uk-card-secondary"  style={{minHeight: "250px"}}>
                            <div className="pa2">
                                <p className="uk-text-bold text-light mb0 pb0">
                                How do conditions throughout the Martian atmosphere affect rates of atmospheric escape?
                                </p>
                            </div>
                            <div className="ph2 animated-card-text relative">
                                <div className="absolute left-1" style={{fontSize: "6rem", lineHeight: "6rem", color: "#0a0a0a", fontWeight: "800", opacity: "0.5"}}>
                                    B
                                </div>
                                <p className="text-light uk-text-left uk-text-small pl5">
                                    Correlate rates of thermal and photochemical atmospheric escape with condition in the collisional Martian atmosphere.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
                <div>
                    <div className="animated-card animated-card-text-trigger">
                        <div className="flex flex-column justify-center uk-card-secondary"  style={{minHeight: "250px"}}>
                            <div className="pa2">
                                <p className="uk-text-bold text-light mb0 pb0">
                                How do key constituents in the Martian exosphere behave temporally and spatially?
                                </p>
                            </div>
                            <div className="ph2 animated-card-text relative">
                                <div className="absolute left-1" style={{fontSize: "6rem", color: "#0a0a0a", fontWeight: "800", lineHeight: "6rem", opacity: "0.5"}}>
                                    C
                                </div>
                                <p className="text-light uk-text-left uk-text-small pl5">
                                    Charaterize the state of Martian lower atmosphere on global scales and it's geographic, diurnal and seasonal variability.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div> */}
                    {/* <div className="standard-container standard-bg">
                        <h3>
                            The EMM - Hope Probe team will:
                </h3>
                        <uk className="uk-list-disc">
                            <li>
                                Integrate with the global Mars science community on key questions that no other mission has addressed.
                    </li>
                            <li>
                                Study why Mars is losing its upper atmosphere to space by tracking the behaviour and escape of hydrogen and oxygen, the building blocks of water.
                    </li>
                            <li>
                                Investigate the connection between the lower and upper levels of the Martian atmosphere.
                    </li>
                            <li>
                                Create the first global picture of how the Martian atmosphere changes through the day and between seasons.
                    </li>
                            <li>
                                Observe weather phenomena, such as dust storms, changes in temperature, and how the atmosphere interacts with the topography.
                    </li>
                            <li>
                                Reveal the causes of Martian surface corrosion.
                    </li>
                            <li>
                                Search for connections between today’s weather and the ancient climate of the red planet
                    </li>
                        </uk>
                    </div> */}


                </div>
            </section>
        </main>
    </Layout>
)

export default LaunchVehiclePage
